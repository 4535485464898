<template>
  <div class="container-xs" style="display: grid; gap: 2rem">
    <AppHeader back-to="/profile" />
    <div>
      <p class="app-subtitle">{{ $t('profile.profile') }}</p>
      <SettingsCards
        id="profile-cards"
        small
        style="margin-top: 1rem"
        :data="profileData"
      />
    </div>
    <div>
      <p class="app-subtitle">{{ $t('profile.social') }}</p>
      <SettingsCards small style="margin-top: 1rem" :data="userSocials" />
    </div>
    <div>
      <p class="app-subtitle">{{ $t('profile.help') }}</p>
      <SettingsCards small style="margin-top: 1rem" :data="helpData" />
    </div>
  </div>
</template>

<script>
import { displayUserLocation } from '../../services/LocationService'
import SettingsCards from '@/components/common/SettingsCards.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { AuthProviderEnum } from '../../../../util/gen/proto/commons/enum_pb'
import AppHeader from '../../components-rf/AppHeader.vue'
import { AUTH_PROVIDERS } from '../../constants/AUTH_PROVIDERS'
import { ELUDE } from '../../constants/ELUDE'
import { date } from '../../other/utils/helpers/date'
import { appAnalytics } from '../../services/analytics/analytics'

export default {
  name: 'EditProfile',
  components: { SettingsCards, AppHeader },
  mounted() {
    appAnalytics.page({ title: 'profile_edit_screen_view' })
  },
  methods: mapActions('UserModule', ['addSocial', 'getUser']),
  computed: {
    ...mapState('UserModule', ['user']),
    ...mapState('TitleModule', ['titles']),
    ...mapState('CountryModule', ['countries']),
    ...mapGetters('UserModule', ['userLocation']),
    userSocials() {
      const socials = this.user.socialsList
      return AUTH_PROVIDERS.map(({ id, name }) => {
        const savedSocial = socials.find(el => el.provider === id)
        const onClick = () => this.addSocial(id).then(() => this.getUser())
        return {
          label: name,
          value: !savedSocial
            ? this.$t('profile.social-account-not-connected')
            : savedSocial?.fallbackField ??
              this.$t('profile.social-account-added'),
          click: this.user.provider === id ? false : onClick,
        }
      })
    },
    dob() {
      if (!this.user.dateOfBirth) return ''
      return date.formatUTC('-ms -d, -y', this.user.dateOfBirth)
    },
    profileData() {
      const phoneCountry = this.countries.find(
        ({ id }) => id === this.user.phoneCountryId,
      )
      const profileDataList = [
        {
          label: this.$t('profile.first-name'),
          value: this.user.firstName,
          id: 'firstName',
          click: () => this.$router.push('/profile/edit/first-name'),
        },
        {
          label: this.$t('profile.last-name'),
          value: this.user.lastName,
          id: 'lastName',
          click: () => this.$router.push('/profile/edit/last-name'),
        },
        {
          label: this.$t('profile.title'),
          value: this.user.titleId
            ? this.titles.find(({ id }) => id === this.user.titleId)?.title
            : '',
          id: 'title',
          click: () => this.$router.push('/profile/edit/title'),
        },
        {
          label: this.$t('profile.email'),
          value: this.user.email,
          id: 'email',
          // click: () => this.$router.push('/profile/edit/email'),
        },
        {
          label: this.$t('profile.phone'),
          value:
            this.user.phoneCountryId && phoneCountry
              ? phoneCountry.dialCode + ' ' + this.user.phone
              : '',
          id: 'phone',
          click: () => this.$router.push('/profile/edit/phone'),
        },
        {
          label: this.$t('profile.dob'),
          value: this.dob,
          id: 'dob',
          click: () => this.$router.push('/profile/edit/date-of-birth'),
        },
        {
          label: this.$t('profile.location'),
          value: displayUserLocation(this.userLocation),
          id: 'location',
          click: () => this.$router.push('/profile/edit/location'),
        },
        {
          disabled: this.user.provider !== AuthProviderEnum.EMAIL,
          label: this.$t('profile.change-password'),
          id: 'password',
          click: () => this.$router.push('/profile/edit/password'),
        },
        {
          label: this.$t('profile.delete-account'),
          id: 'delete-account',
          click: () => this.$router.push('/profile/edit/delete-account'),
          optLabelColor: 'red',
        },
      ].filter(({ disabled }) => !disabled)

      return profileDataList
    },
    helpData() {
      return [
        {
          label: this.$t('profile.get-in-touch'),
          click: () => window.open(ELUDE.SUPPORT_MAIL),
        },
        {
          label: this.$t('settings.tos-title'),
          click: () => {
            this.$router.push('/terms-of-use')
          },
        },
        {
          label: this.$t('settings.pos-title'),
          click: () => {
            this.$router.push('/privacy-policy')
          },
        },
      ]
    },
  },
}
</script>

<style scoped lang="scss"></style>
